<template>
    <v-dialog v-model="dialog" @keydown.esc="cancel" v-if="log" fullscreen>
        <v-card>

            <v-toolbar dark color="primary">

                <v-toolbar-title>
                    {{ title }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon dark @click="cancel">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>

            <v-card-text>
                <v-simple-table dense>
                    <tbody>
                        <tr>
                            <td><b>Módosítás időpontja:</b></td>
                            <td>{{ log.created_on }} <b>({{ log.user.name
                            }})</b>
                            </td>
                        </tr>
                    </tbody>
                </v-simple-table>

                <div v-html="diffHTML">
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import * as Diff2Html from 'diff2html';
import 'diff2html/bundles/css/diff2html.min.css';
const Diff = require('diff');

export default {
    name: "ChangelogModal",
    data: () => ({
        log: null,
        resolve: null,
        reject: null,
        dialog: false,
        diffHTML: null,
    }),
    methods: {
        open: async function (log, title) {
            this.log = log;
            this.dialog = true;
            this.title = title;
            var diffs = Diff.createTwoFilesPatch(this.title, this.title, log.before, log.after);
            this.diffHTML = Diff2Html.html(diffs, {
                drawFileList: true,
                matching: "lines",
                outputFormat: "side-by-side",
            });
            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },
        cancel: function () {
            this.resolve(null);
            this.dialog = false;
        }
    },
};
</script>