<template>
    <v-container fluid>
        <ChangelogModal ref="changelogModal"></ChangelogModal>
        <!-- <div v-html="article.content" style="max-width: 100%; overflow-x: auto;"></div> -->
        <v-row>
            <v-col cols="12">
                <v-card>
                    <v-card-title class="grey lighten-2" style="border-bottom: 1px solid; margin-bottom:15px;">

                        <v-spacer></v-spacer>
                        <v-btn color="primary" style="min-width: 100px;"
                            :to="{ name: 'wiki.article.edit', params: { articleId: this.article.id } }">Szerkeszt
                        </v-btn>
                    </v-card-title>
                    <v-card-text class="wikiArticleContent" v-html="article.content" style="overflow-x: scroll" />
                    <v-card-actions class="grey lighten-2" style="border-bottom: 1px solid; margin-top:15px;">
                        <v-simple-table dense style="width: 100%;">
                            <tbody>
                                <tr v-if="article.logs.length > 0">
                                    <td><b>Utoljára szerkesztve:</b></td>
                                    <td>{{ article.updated_on }} <b>({{ article.logs[article.logs.length - 1].user.name
                                    }})</b>
                                    </td>
                                </tr>
                                <tr>
                                    <td><b>Dokumentum létrehozva:</b></td>
                                    <td>{{ article.created_on }} <b>({{ article.user.name }})</b></td>
                                </tr>
                                <tr>
                                    <td><b>Változtatások:</b></td>
                                    <td>
                                        <ul>
                                            <li v-for="log in article.logs" :key="log.id">
                                                <a @click="onLogEntryClicked(log)">{{ log.created_on }}</a> <b>({{
                                                log.user.name
                                                }})</b>
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                            </tbody>

                        </v-simple-table>
                    </v-card-actions>
                </v-card>

            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { getWikiArticle } from "../api/wiki";

import ChangelogModal from "../components/ChangelogModal.vue";
export default {
    name: "WikiPage",
    data: () => ({
        article: {},
        loading: true,
        logModalVisible: false,
        log: {}
    }),
    beforeRouteUpdate: async function (to, from, next) {
        if (to.params.articleId !== from.params.articleId) {
            await getWikiArticle(to.params.articleId).then((response) => {
                this.article = response.data;
                this.$store.commit("app/setTitle", this.article.title);
            });

            next();
        }
    },
    mounted: function () {
        getWikiArticle(this.$route.params.articleId).then((response) => {
            this.article = response.data;
            this.$store.commit("app/setTitle", this.article.title);
        }).catch((err) => {
            if (err.response.status === 404) {
                this.$router.push({ name: "404" });
            }
        })
            .finally(() => this.loading = false);
    },
    methods: {
        onLogEntryClicked: function (log) {
            this.$refs.changelogModal.open(log, this.article.title);
        }
    },
    components: { ChangelogModal }
};
</script>

<style>
/* .articleContent td,
.articleContent th {
    border: 1px solid #ddd;
    padding: 8px;
}

.articleContent tr:nth-child(even) {
    background-color: #f2f2f2;
}

.articleContent tr:hover {
    background-color: #ddd;
}

.articleContent th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #1976d2;
    color: white;
}

.articleContent th {
    background-color: #1976d2 !important;
    color: white !important;
} */

.wikiArticleContent h1 {
    margin-bottom: 15px;
    margin-top: 15px;
}

.wikiArticleContent {
    line-height: 1.34rem;
}
</style>